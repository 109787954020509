<template>
  <div>
    <b-sidebar
      id="sidebar-search"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Search</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12" class="mb-1">
            <v-select
              v-model="searchPerson"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="searchOptions"
              :clearable="false"
              label="text"
              :reduce="(opt) => opt.value"
              placeholder="Select"
              :disabled="studentLoading"
            />
          </b-col>
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="loadSearch()"
                style="cursor: pointer"
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="searchQuery"
                @keyup.enter="loadSearch()"
                :placeholder="
                  searchPerson == 'student' ? 'Enter name or gr#' : 'Enter name'
                "
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>

      <b-table
        class=""
        show-empty
        :busy="studentLoading"
        ref="studentSelect"
        :items="searchPerson == 'student' ? searchStd : searchStaff"
        :fields="searchPerson == 'student' ? fields : fields2"
        responsive
        hover
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                :src="data.item.picture"
                variant="light-primary"
              />
            </template>

            <b-badge variant="light-primary">
              {{ data.item.name }}
            </b-badge>

            <span
              class="font-weight-bold d-block text-nowrap"
              style="margin: 4px 0px"
            >
              <b-badge variant="light-primary">
                F/N: {{ data.item.father_name }}
              </b-badge>
            </span>

            <small class="text-muted">
              <b-badge variant="light-primary">
                GR - {{ data.item.grNo }}
              </b-badge>
              <b-badge variant="light-primary" style="margin-inline: 10px">
                {{ data.item.meta_class }}
              </b-badge>
            </small>
          </b-media>
        </template>

        <template #cell(staff)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.picture"
                variant="light-primary"
              />
            </template>

            <b-badge variant="light-primary" class="mb-25">
              {{ data.item.name }}
            </b-badge>
            <span class="font-weight-bold d-block text-nowrap">
              <b-badge variant="light-primary">
                {{ data.item.designation }}
              </b-badge>
            </span>
          </b-media>
        </template>
      </b-table>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Sales Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12" v-if="vocherObj">
            <b-form-group
              label="Voucher Type"
              invalid-feedback="Voucher Type is required."
              ref="voucher"
            >
              <v-select
                v-model="vocherObj.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="voucherOptions"
                :reduce="(opt) => opt.text"
                label="text"
                :clearable="false"
                ref="vt"
                placeholder="Select type"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="credObj">
            <b-form-group
              label="Credit Account"
              invalid-feedback="Account is required."
              ref="cred_account"
            >
              <v-select
                :filterBy="dropFilter"
                v-model="credObj.valueNumber"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accList"
                :reduce="(opt) => opt.id"
                label="title"
                :clearable="false"
                :disabled="accLoading"
                placeholder="Select account"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="debObj">
            <b-form-group
              label="Debit Account"
              invalid-feedback="Account is required."
              ref="deb_account"
            >
              <v-select
                :filterBy="dropFilter"
                v-model="debObj.valueNumber"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accList"
                :reduce="(opt) => opt.id"
                label="title"
                :clearable="false"
                :disabled="accLoading"
                placeholder="Select account"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-2">
            <b-button
              variant="success"
              @click="saveSettings()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row>
      <b-col xl="8" lg="8" md="12">
        <b-row>
          <b-col>
            <b-form-group label="Search">
              <b-form-input
                v-model.trim="productQuery"
                placeholder="Enter product name to search"
                @keyup.enter="LoadProduct()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="12" v-if="dataLoading" class="align-items-center">
            <b-spinner
              style="width: 5rem; height: 5rem"
              type="grow"
              variant="primary"
              label="loading"
              class="mt-3 mb-3"
            ></b-spinner>
          </b-col>
          <template v-else>
            <b-col
              md="12"
              v-if="products.length == 0"
              class="align-items-center"
            >
              <h4 class="mt-3 mb-3">No Records Found</h4>
            </b-col>
            <b-col
              xl="4"
              lg="6"
              md="4"
              v-for="(item, ind) in products"
              :key="item.id"
            >
              <b-card no-body>
                <div class="item-img text-center">
                  <b-img
                    alt=""
                    class="card-img-top"
                    :src="item.image"
                    height="180"
                  />
                </div>

                <b-card-body>
                  <h5 class="font-weight-bolder">{{ item.name }}</h5>
                  <div
                    class="d-flex align-items-center justify-content-between flex-wrap"
                  >
                    <!-- <p class="mb-0">Quantity: {{ item.quantity }}</p> -->
                    <p class="mb-0">
                      <span
                        :class="
                          item.quantity == 0 ? 'text-danger' : 'text-success'
                        "
                        >{{ item.quantity == 0 ? "Out Stock" : "In stock" }}
                      </span>
                      ({{ item.quantity }})
                    </p>
                    <h6 class="mb-0">Rs.{{ item.sellingPrice }}</h6>
                  </div>
                  <!-- <p class="mb-0">
                    <span
                      :class="item.quantity == 0 ? 'text-danger' : 'text-success'"
                      >{{ item.quantity == 0 ? "Out Stock" : "In stock" }}
                    </span>
                    ({{ item.quantity }})
                  </p> -->
                  <b-button
                    v-if="item.quantity == 0"
                    variant="outline-secondary"
                    class="btn-icon mt-2"
                    block
                  >
                    <!-- <feather-icon icon="ShoppingCartIcon" class="mr-50" /> -->
                    <span>Out Stock</span>
                  </b-button>
                  <b-button
                    v-else
                    variant="primary"
                    class="btn-icon mt-2"
                    @click="addItem(item)"
                    block
                  >
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span>Add to Cart</span>
                  </b-button>
                </b-card-body>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-col>
      <b-col xl="4" lg="4" md="12">
        <b-row>
          <b-col>
            <b-form-group>
              <div class="d-flex align-items-start justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Enter SKU or scan with barcode</label
                >
                <b-spinner v-if="settLoading" small variant="secondary" />
                <feather-icon
                  v-else
                  icon="SettingsIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="loadSettings(true)"
                  v-b-tooltip.hover.top
                  title="Settings"
                />
              </div>
              <b-form-input
                v-model.trim="skuQuery"
                placeholder="SKU number"
                @keyup.enter="loadSku()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card>
          <!-- <div class="d-flex align-items-start justify-content-end">
            <feather-icon
              icon="SearchIcon"
              @click="openSearch()"
              size="22"
              class="cursor-pointer"
              v-b-tooltip.hover.left
              title="Search Student"
            />
          </div> -->

          <div
            class="d-flex align-items-start justify-content-center mb-1 position-relative"
          >
            <div v-if="currentStudent" class="text-center">
              <b-avatar
                size="78"
                :src="currentStudent.picture"
                variant="light-primary"
                @click.native="openSearch()"
                class="cursor-pointer"
                v-b-tooltip.hover.top
                title="Click to search student or staff"
              />
              <div class="mt-1">
                <b-badge variant="light-primary" class="mb-50">
                  {{ currentStudent.name }}
                </b-badge>
                <br />
                <b-badge variant="light-primary">
                  {{ currentStudent.meta_class }}
                </b-badge>
              </div>

              <feather-icon
                icon="PrinterIcon"
                size="22"
                class="cursor-pointer position-absolute"
                style="top: 0; right: 0"
                v-b-tooltip.hover.top
                title="Print"
              />
              <!-- @click="printChallan()" -->
              <!-- <h5 class="text-center mt-1">
                <span class="label">{{ currentStudent.name }}</span>

              </h5>
              <h5 class="text-center">
                <span class="label">{{ currentStudent.meta_class }}</span>
              </h5> -->
            </div>
            <div v-else-if="currentStaff" class="text-center">
              <b-avatar
                size="78"
                :src="currentStaff.picture"
                variant="light-primary"
                @click.native="openSearch()"
                class="cursor-pointer"
                v-b-tooltip.hover.top
                title="Click to search student or staff"
              />
              <div class="mt-1">
                <b-badge variant="light-primary" class="mb-50">
                  {{ currentStaff.name }}
                </b-badge>
                <br />
                <b-badge variant="light-primary">
                  {{ currentStaff.designation }}
                </b-badge>
              </div>
            </div>
            <div v-else class="text-center">
              <b-avatar
                size="78"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                variant="light-primary"
                @click="openSearch()"
                button
                class="cursor-pointer"
                v-b-tooltip.hover.top
                title="Click to search student or staff"
              />
            </div>
          </div>
          <div class="">
            <h6 class="price-title mb-1">Price Details</h6>
            <hr />
            <ul class="list-unstyled set-height">
              <li class="mb-1" v-if="cartItems.length == 0">
                <div class="">No Items in cart</div>
              </li>
              <li
                class="d-flex mb-1"
                v-for="(item, ind) in cartItems"
                :key="item.id"
                style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1)"
              >
                <b-img
                  :alt="item.name"
                  :src="item.image"
                  width="100"
                  style="border-radius: 7px"
                />
                <div class="p-1 w-100" style="position: relative">
                  <div class="font-weight-bolder">{{ item.name }}</div>
                  <div class="font-weight-bold">Rs.{{ item.price }}</div>
                  <b-form-spinbutton
                    v-model="item.qty"
                    size="sm"
                    class="mr-75 mt-50"
                    inline
                    :max="item.quantity"
                    @input="getPrice(item, ind)"
                  />
                  <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer text-danger mr-50"
                    size="16"
                    @click="removeItem(ind)"
                    style="position: absolute; top: 4px; right: 4px"
                  />
                </div>
              </li>
              <!-- <li
                class="d-flex justify-content-between mb-1"
                v-for="(item, ind) in cartItems"
                :key="item.id"
              >
                <div class="">
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-danger mr-50"
                    size="18"
                    @click="removeItem(ind)"
                  />
                  <b-form-spinbutton
                    v-model="item.qty"
                    size="sm"
                    class="mr-75"
                    inline
                    :max="item.quantity"
                    @input="getPrice(item, ind)"
                  />
                  <span class="">{{ item.name }}</span>
                </div>
                <div class="">Rs.{{ item.price }}</div>
              </li>
              -->

              <!-- <li class="d-flex justify-content-between mb-1">
                <div class="">Bag Discount</div>
                <div class="text-success">-25$</div>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="">Estimated Tax</div>
                <div class="">$1.3</div>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="">EMI Eligibility</div>
                <a href="javascript:void(0)" class="text-primary">Details</a>
              </li>
              <li class="d-flex justify-content-between mb-1">
                <div class="">Delivery Charges</div>
                <div class="text-success">Free</div>
              </li> -->
            </ul>
          </div>
          <div>
            <hr />
            <ul class="list-unstyled">
              <!-- <li class="d-flex justify-content-between mb-1">
                <div class="font-weight-bolder">Total</div>
                <div class="font-weight-bolder">Rs.{{ totalPrice }}</div>
              </li> -->
              <b-form-group label="Total">
                <b-form-input
                  disabled
                  v-model="totalPrice"
                  class="form-control"
                  placeholder="Total"
                />
              </b-form-group>
              <b-form-group
                label="Receiving amount"
                invalid-feedback="Invalid amount entered"
              >
                <b-form-input
                  ref="paid"
                  type="number"
                  v-model="receiveObj.amount"
                  @input="getBalance()"
                  class="form-control"
                  placeholder="Receiving amount"
                />
              </b-form-group>
              <b-form-group
                label="Balance"
                invalid-feedback="Balance is required."
              >
                <b-form-input
                  disabled
                  v-model="balance"
                  class="form-control"
                  placeholder="Balance"
                />
              </b-form-group>
              <b-form-group
                label="Receiving Date"
                invalid-feedback="Receiving date is required."
                ref="date"
              >
                <flat-pickr
                  :config="config"
                  v-model="receiveObj.rcvDate"
                  class="form-control"
                  placeholder="Select Date."
                  @on-change="checkDate()"
                />
              </b-form-group>
              <b-form-group
                label="Note"
                invalid-feedback="Note is required."
                ref="note"
              >
                <b-form-textarea
                  placeholder="Enter note"
                  v-model="receiveObj.note"
                  rows="1"
                  max-rows="5"
                ></b-form-textarea>
              </b-form-group>
            </ul>
            <b-button
              variant="primary"
              block
              :disabled="saving"
              @click="confirmOrder()"
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Confirm Payment</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      visibility: false,
      searchQuery: "",
      studentLoading: false,
      searchStd: [],
      searchStaff: [],
      currentStudent: null,
      currentStaff: null,
      searchPerson: "student",
      searchOptions: [
        { text: "Student", value: "student" },
        { text: "Staff", value: "staff" },
      ],
      fields: ["student"],
      fields2: ["staff"],
      cartItems: [],
      skuQuery: "",
      productQuery: "",
      products: [],
      receiveObj: {
        rcvDate: new Date().toJSON(),
        amount: 0,
        note: "",
      },
      saving: false,
      balance: 0,
      dataLoading: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      voucherOptions: [
        { text: "CPV", value: "cpv" },
        { text: "BPV", value: "bpv" },
        { text: "CRV", value: "crv" },
        { text: "BRV", value: "brv" },
        { text: "JV", value: "jv" },
      ],
      visibility2: false,
      accList: [],
      accLoading: false,
      vocherObj: null,
      credObj: null,
      debObj: null,
      request: false,
      settLoading: false,
    };
  },
  computed: {
    totalPrice() {
      let total = this.cartItems.reduce((sum, el) => sum + el.price, 0);
      return total;
    },
  },
  created() {
    this.LoadData();
    this.LoadAccounts();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async loadSettings(val) {
      this.settLoading = true;
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["sales_voucher", "sales_credit_account", "sales_debit_account"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      // console.log(res);
      this.settLoading = false;

      this.vocherObj = res.find((el) => el.key == "sales_voucher");
      if (!this.vocherObj) {
        this.vocherObj = {
          id: 0,
          key: "sales_voucher",
          value: "",
          valueBool: true,
          valueNumber: 0,
          campusID: this.$store.state.userData.cId,
        };
      }
      this.credObj = res.find((el) => el.key == "sales_credit_account");
      if (!this.credObj) {
        this.credObj = {
          id: 0,
          key: "sales_credit_account",
          value: "",
          valueBool: true,
          valueNumber: 0,
          campusID: this.$store.state.userData.cId,
        };
      }
      this.debObj = res.find((el) => el.key == "sales_debit_account");
      if (!this.debObj) {
        this.debObj = {
          id: 0,
          key: "sales_debit_account",
          value: "",
          valueBool: true,
          valueNumber: 0,
          campusID: this.$store.state.userData.cId,
        };
      }

      // open settings if val is true
      if (val) {
        this.visibility2 = true;
        this.resetStates();
      }
    },
    resetStates() {
      if (this.vocherObj) {
        var elem = this.$refs["voucher"];
        elem.state = undefined;
      }
      if (this.credObj) {
        var elem = this.$refs["cred_account"];
        elem.state = undefined;
      }
      if (this.debObj) {
        var elem = this.$refs["deb_account"];
        elem.state = undefined;
      }
    },

    async LoadAccounts() {
      this.accLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.accList = await this.get(obj);
      // console.log("acc", this.accList);
      this.accLoading = false;
    },
    dropFilter(opt, label, search) {
      // console.log(opt, label, search);
      return (
        opt.title.toLowerCase().match(search.toLowerCase()) ||
        opt.code.toLowerCase().match(search.toLowerCase())
      );
    },

    checkVoucher() {
      if (this.vocherObj) {
        var elem = this.$refs["voucher"];
        if (!this.vocherObj.value) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkCredit() {
      if (this.credObj) {
        var elem = this.$refs["cred_account"];
        if (!this.credObj.valueNumber) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkDebit() {
      if (this.debObj) {
        var elem = this.$refs["deb_account"];
        if (!this.debObj.valueNumber) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    async saveSettings() {
      let v1 = this.checkVoucher();
      let v2 = this.checkCredit();
      let v3 = this.checkDebit();

      if (!v1 || !v2 || !v3) {
        this.$bvToast.toast("Please enter the values correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
        return;
      }

      let data = [];
      if (this.vocherObj) data.push(this.vocherObj);
      if (this.credObj) data.push(this.credObj);
      if (this.debObj) data.push(this.debObj);

      // console.log(data);

      if (data.length > 0) {
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: data,
          message: "Sales Settings saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.loadSettings(false);
          this.visibility2 = false;
        }
      } else {
        this.visibility2 = false;
      }
    },

    printChallan() {
      let toshow =
        this.cartItems.length > 0
          ? this.cartItems.filter((el) => el.sku)
          : this.products.length > 0
          ? this.products.filter(
              (el) => el.sku && el.quantity > 0 && el.sellingPrice > 0
            )
          : [];
      let skuNums = toshow.map((el) => el.sku).join(",");
      // console.log(skuNums);
      if (skuNums) {
        let url =
          `https://${this.reportDomain}.myskool.app/Fee/InvChallan?dbb=` +
          this.$store.state.userData.db +
          "&sID=" +
          this.currentStudent.id +
          "&cID=" +
          this.currentStudent.campusID +
          "&sku=" +
          skuNums;

        window.open(url, "_blank");
      } else {
        this.$bvToast.toast("Please add product to cart", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      }
    },

    async LoadClassProducts() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "posStock/LoadByClass?db=" +
          this.$store.state.userData.db +
          "&clsID=" +
          this.currentStudent.class_current,
        token: this.$store.state.userData.token,
      };
      this.products = await this.get(obj);

      this.dataLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "posStock/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.products = await this.get(obj);
      this.dataLoading = false;
    },

    async LoadProduct() {
      if (this.productQuery == "") {
        this.LoadData();
      } else {
        this.dataLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "posStock/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.productQuery,
          token: this.$store.state.userData.token,
        };
        this.products = await this.get(obj);
        this.dataLoading = false;
      }
    },
    async loadSku() {
      if (this.skuQuery !== "") {
        var obj = {
          url:
            this.$store.state.domain +
            "posStock/SearchSku?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&sku=" +
            this.skuQuery,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        console.log(res);
        if (res) {
          this.addItem(res);
        } else {
          this.$bvToast.toast("No product found", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-right",
          });
        }
      }
    },

    getBalance() {
      var elem = this.$refs["paid"];
      let x = parseInt(this.receiveObj.amount);
      if (isNaN(x) || x <= 0 || x > this.totalPrice) {
        elem.state = false;
      } else {
        this.receiveObj.amount = x;
        this.balance = this.totalPrice - this.receiveObj.amount;
        elem.state = undefined;
      }
    },
    getPrice(item, ind) {
      item.price = item.sellingPrice * item.qty;
      this.cartItems.splice(ind, 1, item);
      this.receiveObj.amount = this.totalPrice;
      this.getBalance();
    },

    addItem(item) {
      if (item.quantity == 0) {
        this.$bvToast.toast("This Product is out of stock!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else if (item.sellingPrice == 0) {
        this.$bvToast.toast("Selling price is not defined.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        let obj = this.cartItems.find((el) => el.id == item.id);
        if (!obj) {
          item.qty = 1;
          item.price = item.sellingPrice;
          this.cartItems.push(item);
          this.receiveObj.amount = this.totalPrice;
        }
      }
      this.getBalance();
    },
    removeItem(ind) {
      this.cartItems.splice(ind, 1);
      this.receiveObj.amount = this.totalPrice;
      this.getBalance();
    },

    checkBalance() {
      var elem = this.$refs["paid"];
      let x = parseInt(this.receiveObj.amount);
      if (isNaN(x) || x <= 0 || x > this.totalPrice) {
        return (elem.state = false);
      } else {
        this.receiveObj.amount = x;
        this.balance = this.totalPrice - this.receiveObj.amount;
        elem.state = undefined;
        return true;
      }
    },
    checkDate() {
      var elem = this.$refs["date"];
      if (!this.receiveObj.rcvDate) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async confirmOrder() {
      // console.log(this.cartItems);
      if (this.cartItems.length == 0) {
        this.$bvToast.toast("Please add product to cart", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      }
      //  else if (!this.currentStudent && !this.currentStaff) {
      //   this.$bvToast.toast("Please select the student", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-right",
      //   });
      // }
      else if (this.checkBalance() == false) {
        this.$bvToast.toast("Invalid amount entered", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else if (this.checkDate() == false) {
        this.$bvToast.toast("Please enter receiving date", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        // console.log(this.cartItems);
        this.saving = true;
        let data = this.cartItems.map((el) => ({
          id: 0,
          billID: 0,
          sID: this.currentStudent ? this.currentStudent.id : 0,
          tID: this.currentStaff ? this.currentStaff.id : 0,
          productID: el.id,
          productName: el.name,
          productPrice: el.sellingPrice,
          total: el.price,
          qty: el.qty,
          campusID: this.$store.state.userData.cId,
        }));
        let obj = {
          id: 0,
          sID: this.currentStudent ? this.currentStudent.id : 0,
          tID: this.currentStaff ? this.currentStaff.id : 0,
          netTotal: this.totalPrice,
          rcvd: this.receiveObj.amount,
          discount: 0,
          balance: this.balance,
          note: this.receiveObj.note,
          rcvdDate: this.receiveObj.rcvDate,
          userID: this.$store.state.userData.userID,
          postedOn: new Date().toJSON().split("T")[0],
          campusID: this.$store.state.userData.cId,
        };

        // console.log(data, obj);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "posOrders/SaveOrder?db=" +
            this.$store.state.userData.db,
          body: {
            order: obj,
            details: data,
          },
          message: "Order confirmed successfully!",
          context: this,
          returnData: true,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        this.currentStudent = null;
        this.currentStaff = null;

        if (Array.isArray(status)) {
          this.cartItems = [];
          this.receiveObj = {
            rcvDate: this.receiveObj.rcvDate,
            amount: 0,
            note: "",
          };
          this.balance = 0;
          status.forEach((el) => {
            let ind = this.products.findIndex((elem) => elem.id == el.id);
            if (ind != -1) {
              this.products.splice(ind, 1, el);
            }
          });
        }
      }
    },

    openSearch() {
      this.visibility = true;
    },
    async loadSearch() {
      if (this.searchQuery.trim() !== "") {
        this.studentLoading = true;
        if (this.searchPerson == "student") {
          let myitem = await this.get({
            url:
              this.$store.state.domain +
              "Students/Search?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&keyword=" +
              this.searchQuery +
              "&type=&status=present",
            token: this.$store.state.userData.token,
          });
          this.searchStd = myitem;
        } else {
          let myitem = await this.get({
            url:
              this.$store.state.domain +
              "staff/Search?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&keyword=" +
              this.searchQuery +
              "&status=present",
            token: this.$store.state.userData.token,
          });
          this.searchStaff = myitem;
        }
        // console.log("stud", this.searchStd);
        this.studentLoading = false;
      }
    },
    onRowSelected(row) {
      // console.log(row[0]);
      if (row[0]) {
        if (this.searchPerson == "student") {
          this.currentStudent = row[0];
          this.currentStaff = null;
          // this.LoadClassProducts();
        } else {
          this.currentStaff = row[0];
          this.currentStudent = null;
        }
        this.visibility = false;
      }
    },
  },
};
</script>
<style>
.set-height {
  min-height: 250px;
  /* position: relative;  */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
